<template>
  <div class="mayo-navbar navbar">
    <router-link
      class="logo"
      to="/">
      <img :src="require('@images/logo_platewhite.png')">
    </router-link>
    <b-datepicker
      v-model="localSelectedDate"
      :placeholder="$t('navbar.dateSelector.placeholder')"
      icon="calendar-alt"
      :dateFormatter="dateFormatter"
      :mobileNative="false"/>
    <custom-time-range-selector :selectedTimeRange.sync="localSelectedTimeRange"/>
    <hub-selector
      v-if="hubs.length > 0"
      :value.sync="localSelectedHubId"/>
    <router-link
      v-for="nav in mainNav"
      :key="`main_nav_${nav.label}`"
      :to="nav.path"
      class="nav"
      :class="{'selected': nav.path === currentRoute}">
      {{ nav.label }}
    </router-link>
    <more-nav :sections="moreNav"/>
  </div>
</template>

<script>
import { PAGES, getFilteredNavigations } from '@js/navigations'
import { mapActions, mapGetters } from 'vuex'
import { formatDate } from '@js/utils'
import queryParams from '@js/query-params-cache'

const CustomTimeRangeSelector = () => import('./CustomTimeRangeSelector')
const HubSelector = () => import('./HubSelector')
const MoreNav = () => import('./MoreNav')

export default {
  name: 'navbar',
  components: {
    CustomTimeRangeSelector,
    HubSelector,
    MoreNav
  },
  data() {
    return {
      localSelectedDate: new Date(),
      localSelectedTimeRange: null,
      localSelectedHubId: null
    }
  },
  computed: {
    ...mapGetters('hub', [
      'hubs'
    ]),
    ...mapGetters('sessionUser', [
      'role'
    ]),
    currentRoute() {
      // TODO: restore this when all the pages are created
      // return this.$route.path
      return this.$route.path === '/' ? null : this.$route.path
    },
    mainNav() {
      return getFilteredNavigations(this.role, [
        PAGES.ASSIGNMENT,
        PAGES.PRINT,
        PAGES.PACK_AND_DISPATCH,
        PAGES.TASKS
      ])
    },
    moreNav() {
      const fulfilmentSection = {
        section: this.$t('navbar.navs.fulfilment.title'),
        items: getFilteredNavigations(this.role, [
          PAGES.ROSTER,
          PAGES.BILLING
        ])
      }
      const adminSection = {
        section: this.$t('navbar.navs.admin.title'),
        items: getFilteredNavigations(this.role, [
          PAGES.USERS
        ])
      }
      const navs = [fulfilmentSection, adminSection].filter(section => {
        return section.items.length > 0
      })
      return navs.concat(
        {
          items: [
            {
              label: this.$t('navbar.navs.logout'),
              click: this.logoutRequest
            }
          ]
        }
      )
    }
  },
  watch: {
    localSelectedDate() {
      this.setSelectedDate(this.localSelectedDate)
      queryParams.saveSelectedDate(this.localSelectedDate)
      this.updateUrl()
    },
    localSelectedTimeRange() {
      this.setSelectedTimeRange(this.localSelectedTimeRange)
      queryParams.saveSelectedTimeRange(this.localSelectedTimeRange)
      this.updateUrl()
    },
    localSelectedHubId() {
      this.setSelectedHubIds([this.localSelectedHubId])
      queryParams.saveSelectedHubIds([this.localSelectedHubId])
      this.updateUrl()
    }
  },
  created() {
    this.resetHubStore()

    const queryDate = this.$route.query.date ? new Date(this.$route.query.date) : null
    const queryTime = this.$route.query.time ? this.$route.query.time.split('-').map(v => parseInt(v)) : null
    const queryHub = parseInt(this.$route.query.hub)

    this.localSelectedDate = queryDate || new Date(queryParams.loadSelectedDate() || new Date())
    this.localSelectedTimeRange = queryTime || queryParams.loadSelectedTimeRange()
    this.localSelectedHubId = queryHub || queryParams.loadSelectedHubIds()[0]

    this.getHubsRequest()
  },
  methods: {
    ...mapActions('sessionUser', [
      'logoutRequest'
    ]),
    ...mapActions('hub', [
      'resetHubStore',
      'getHubsRequest'
    ]),
    ...mapActions('filters', [
      'setSelectedDate',
      'setSelectedTimeRange',
      'setSelectedHubIds'
    ]),
    updateUrl() {
      const query = {
        date: formatDate(this.localSelectedDate),
        time: this.localSelectedTimeRange ? this.localSelectedTimeRange.join('-') : null,
        hub: this.localSelectedHubId.toString()
      }
      if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
        this.$router.replace({ name: this.$route.name, query })
      }
    },
    dateFormatter(date) {
      return formatDate(date, { yearFirst: false })
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar.mayo-navbar {
  display: flex;
  align-items: stretch;
  min-width: 910px;
  min-height: 44px;
  padding: $space-xxs;
  background-color: $secondary;

  > * {
    align-self: center;
  }

  .logo,
  .more-nav {
    padding: 0 $space-xs;
  }

  .logo {
    display: flex;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .datepicker,
  .dropdown {
    margin: 0 $space-xxs;

    &::v-deep {
      input {
        font-size: $font-size-h5;
        line-height: $line-height-h4;
      }

      .icon {
        height: 31.5px;
      }
    }
  }

  .datepicker {
    max-width: 125px;
  }

  .nav {
    padding: 0 $space-s;
    margin: 0 $space-xxs;
    font-weight: normal;
    color: $white;
    border-top: $space-xxs solid transparent;
    border-bottom: $space-xxs solid transparent;
    @extend %heading;

    &:hover {
      border-bottom-color: $grey-light;
    }

    &.selected {
      font-weight: bold;
      border-bottom-color: $white;
    }
  }

  .more-nav {
    flex-grow: 1;
    justify-content: flex-end;
  }
}
</style>
