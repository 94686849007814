import cookies from '@js/cookies'

const PREFIX = `${window.location.origin}/${process.env.VUE_APP_ENVIRONMENT}`
const KEYS = {
  DATE: `${PREFIX}/selectedDate`,
  TIME_RANGE: `${PREFIX}/selectedTimeRange`,
  HUBS: `${PREFIX}/selectedHubIds`
}

function loadAttribute(key) {
  const data = cookies.getCookie(key)
  return data && JSON.parse(data)
}
function saveAttribute(key, value, expiresAt = null) {
  const data = JSON.stringify(value)
  cookies.setCookie(key, data, expiresAt)
}

export default {
  loadSelectedDate() {
    return loadAttribute(KEYS.DATE)
  },
  saveSelectedDate(value) {
    saveAttribute(
      KEYS.DATE,
      value,
      // expires at the end of the day
      new Date().setHours(23, 59, 59)
    )
  },
  loadSelectedTimeRange() {
    return loadAttribute(KEYS.TIME_RANGE)
  },
  saveSelectedTimeRange(value) {
    saveAttribute(KEYS.TIME_RANGE, value)
  },
  loadSelectedHubIds() {
    return loadAttribute(KEYS.HUBS)
  },
  saveSelectedHubIds(value) {
    saveAttribute(KEYS.HUBS, value)
  }
}
